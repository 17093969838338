.contact-details {
  padding-top: 90px;

}

.contact-details .container {
  position: relative;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-details .form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-details .contact-form {
  background-color: var(--second-color);
  position: relative;
}

.contact-details .circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #2f2a15);
  position: absolute;
}

.contact-details .circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.contact-details .circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-details .contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #1d423e;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.contact-details form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.contact-details .title {
  color: var(--white-color);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.contact-details .input-container {
  position: relative;
  margin: 1rem 0;
}

.contact-details .input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

.contact-details textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.contact-details .input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.contact-details .input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.contact-details .btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 20px;
  color: #1abc9c;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.contact-details .btn:hover {
  background-color: transparent;
  color: #fff;
}

.contact-details .input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.contact-details .input-container span:before,
.contact-details .input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: var(--main-color);
  top: 50%;
  transform: translateY(-50%);
}

.contact-details .input-container span:before {
  left: 50%;
}

.contact-details .input-container span:after {
  right: 50%;
}

.contact-details .input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.contact-details .input-container.focus span:before,
.contact-details .input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-details .contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-details .contact-info .title {
  color: var(--bg-color);
}
.contact-details .contact-info .info .information p {
  color: var(--bg-color);
}

.contact-details .text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}
.contact-details .info-icons{
    display: flex;
    align-items: center;
    
}
.contact-details .info-icons:nth-child(1) svg{
    margin-top: -25px;
}
.contact-details .info-icons svg{
margin-top: -16px;
margin-left: 5px;
margin-right: 5px;
}
.contact-details .information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.contact-details .icon {
  width: 28px;
  margin-right: 0.7rem;
}

.contact-details .social-media {
  padding: 2rem 0 0 0;
}

.contact-details .social-media p {
  color: #333;
}

.contact-details .social-icons {
  display: flex;
  margin-top: 0.5rem;
}
.contact-details .social-icons svg {
  margin: 5px auto;
  fill: white;
}

.contact-details .social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #aca488, #d2a958);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.contact-details .social-icons a:hover {
  transform: scale(1.05);
}

.contact-details .contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #1abc9c;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
  z-index: -11221;
}

.contact-details .big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #bdbd40, #9b907d);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.contact-details .big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #e5d3d3;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.contact-details .square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .contact-details .form {
    grid-template-columns: 1fr;
  }

  .contact-details .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-details .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .contact-details .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .contact-details .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .contact-details .text {
    margin: 1rem 0 1.5rem 0;
  }

  .contact-details .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .contact-details .container {
    padding: 1.5rem;
  }

  .contact-details .contact-info:before {
    display: none;
  }

  .contact-details .square,
  .contact-details .big-circle {
    display: none;
  }

  .contact-details form,
  .contact-details .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .contact-details .text,
  .contact-details .information,
  .contact-details .social-media p {
    font-size: 0.8rem;
  }

  .contact-details .title {
    font-size: 1.15rem;
  }

  .contact-details .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .contact-details .icon {
    width: 23px;
  }

  .contact-details .input {
    padding: 0.45rem 1.2rem;
  }

  .contact-details .btn {
    padding: 0.45rem 1.2rem;
  }
}
