.whatsapp {
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 14px;
  cursor: pointer;
  color: var(--white-color);
  transition: var(--main-tran);
  background-color: var(--main-color);
  z-index: 111;
}
.whatsapp:first-of-type{
  bottom: 100px;
}
.whatsapp:hover {
  transform: translateY(-10px);
}
.tooltip {
  visibility: hidden;
  width: 280px;
  color: var(--white-color);
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 50px;
  left: 45px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 20px;
  cursor: auto;
  background-color: var(--second-color);
}
.whatsapp.show-tooltip .tooltip {
  visibility: visible;
  opacity: 1;
}
.whatsapp:first-of-type .tooltip .phone-number{
  cursor: pointer;
  text-decoration: underline;
}
