.hero-section {
  padding-top: 100px;
  padding-bottom: 150px;
}
.hero-section .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.hero-section img {
  width: 680px;
  border-radius: 20px;
  max-width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  filter: drop-shadow(0 0 0.35rem rgb(195, 167, 167));
  -webkit-filter: drop-shadow(0 0 0.35rem rgb(195, 167, 167));
}
.hero-section img {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.hero-section img:hover {
  clip-path: none;
}
.hero-section .container .hero-description {
  text-align: right;
  margin: 10px;
}
.hero-description h1 span {
  color: var(--main-color);
}
.hero-description h1,
p {
  color: var(--white-color);
  margin-bottom: 20px;
}
.hero-description h1 {
  font-size: 46px;
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms linear infinite, typeback 4s steps(44) 8s;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}
.hero-description h1.ar {
  border-left: 2px solid hsl(0, 0%, 80%);
}
.hero-description h1.en {
  border-right: 2px solid hsl(0, 0%, 80%);
  font-size: 40px;
}
.hero-description p {
  font-size: 20px;
  width: 100%;
}
.hero-description p.ar {
  text-align: right;
}
.hero-description p.en {
  text-align: left;
}
.hero-description .show-more {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 20px;
  color: var(--white-color);
  margin-bottom: 60px;
}
.hero-description .show-more svg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  fill: var(--white-color);
  background-color: var(--second-color);
  transition: var(--main-tran);
  -webkit-transition: var(--main-tran);
  -moz-transition: var(--main-tran);
  -ms-transition: var(--main-tran);
  -o-transition: var(--main-tran);
}
.hero-description .show-more:hover svg {
  border-radius: 50%;
}
@media (max-width: 1230px) {
  .hero-section .container {
    flex-direction: column;
  }
  .hero-description {
    text-align: center;
  }
  .hero-description p {
    margin-bottom: 20px;
  }
}
@media (min-width: 901px) {
  .hero-description h1 {
    width: 470px !important;
  }
}
@media (max-width: 900px) {
  .hero-description p {
    font-size: 18px;
  }
  .hero-description h1 {
    font-size: 35px;
  }
  .hero-description .show-more {
    margin-bottom: 50px;
  }
  .hero-description h1.en {
    font-size: 30px;
  }
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 350px;
  }
}
@keyframes typeback {
  from {
    width: 350px;
  }
  to {
    width: 0;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: hsl(0, 0%, 80%);
  }
  to {
    border-right-color: transparent;
  }
}
