.clients {
  padding-top: 30px;
}
.clients .container .client-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 30px;
}
.clients .container .client-boxes .client-box {
  position: relative;
  z-index: 99;
  text-align: center;
  border-radius: 20px;
  padding: 15px;
  margin-top: 60px;
  border-bottom: 1px solid var(--main-color);
  background-color: #473d3d;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.clients .container .client-boxes .client-box .client-img {
  width: 250px;
  height: 120px;
  border-radius: 16px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: -50px;
  transform: translateX(-50%);
  transition: var(--main-tran);
}
.clients .container .client-boxes .client-box .client-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
.clients .container .client-boxes .client-box .client-img:hover img {
  transform: scale(1.1);
}
.clients .container .client-boxes .client-box .client-description {
  margin-top: 70px;
  font-size: 18px;
}
.clients .container .client-boxes .client-box .client-description .client-name {
  position: relative;
}
.clients
  .container
  .client-boxes
  .client-box
  .client-description
  .client-name::before {
  content: "";
  border-radius: 6px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 105%;
  z-index: -1;
  transition: 0.7s;
  background-color: var(--main-color);
}
.clients
  .container
  .client-boxes
  .client-box
  .client-description
  .client-name:hover::before {
  height: 100%;
}
@media(max-width:600px){
  .clients .container .client-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}