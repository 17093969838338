.services {
  padding-top: 30px !important;
}

.services .container .about-box {
  height: 240px;
}
.services .container .about-box p {
  line-height: 2;
}
.services .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr)) !important;
  gap: 20px;
}
@media (max-width: 400px) {
  .services .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    gap: 20px;
  }
}
.services .number {
  display: flex;
  justify-content: center;
  align-items: center;
}
