.project-cta{
    color: var(--bg-color);
    background-color: var(--second-color);

    padding: 5px 5px;
    width: 80%;
    display: block;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 11px auto;
}
.project-cta:hover{
    background-color: var(--main-color);
}
.project-boxes{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(484px, 1fr)) !important;
    gap: 30px;
}
@media(max-width:600px){
    .clients .container .project-boxes {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    }
  }