.header {
  width: 100vw;
  box-shadow: 0 0 2px var(--third-color);
}
.header .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  color: var(--white-color);
}
.header .container .logo {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header .container .logo .menu-btn {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  transform: scaleX(0.9);
  font-size: 25px;
  border-radius: 12px;
  color: var(--white-color);
  transition: var(--main-tran);
  background-color: var(--main-color);
}
.header .container .logo a {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header .container .logo a img {
  width: 100px;
}
.header .container .logo a h1 {
  font-size: 24px;
  font-weight: 500;
  transition: var(--main-tran);
}
.header .container .logo a h1:hover {
  color: var(--main-color);
}
.header .container .ul-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  font-size: 19px;
  font-weight: bold;
}
.header .container .ul-links .li-link a {
  position: relative;
  font-weight: bold;
  transition: var(--main-tran);
  color: var(--white-color);
}
.header .container .ul-links .li-link a:hover {
  color: var(--main-color);
}
.header .container .ul-links .li-link a.active {
  color: var(--main-color);
}
.header .container .ul-links .li-link a.active::before {
  content: "";
  width: 100%;
  height: 15px;
  position: absolute;
  top: -33px;
  border-radius: 16px;
  transition: var(--main-tran);
  background-color: var(--main-color);
  box-shadow: 0px 0px 12px 0px var(--second-color);
}
.header .container .ul-links .li-link a.active:hover::before {
  transform: scaleX(1.1);
}
.header .container .ul-links .li-link .dropmenu-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header .container .ul-links .li-link .dropmenu {
  position: fixed;
  margin-left: 12px;
  padding: 18px;
  border-radius: 15px;
  box-shadow: 0 0 7px #a3b9d233;
  background-color: var(--white-color);
  transition: 0.5s;
  width: 180px;
  z-index: 999;
}
.header .container .ul-links .li-link .dropmenu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.header .container .ul-links .li-link .dropmenu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item {
  text-align: right;
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item a {
  margin-right: 20px;
  transition: var(--main-tran);
  color: var(--bg-color);
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item a::before {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--main-color);
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item-en {
  text-align: left;
}
.header .container .ul-links .li-link .dropmenu .dropmenu-item-en a::before {
  left: -13px;
}
.header .container .ul-links .li-link .dropmenu hr {
  border: 1px solid #f6e5c3;
  margin: 10px auto;
}

.header svg:before {
  font-size: 30px;
  margin-top: 6px;
}
.header .container .btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header .container .btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  font-size: 20px;
  color: var(--white-color);
  border-radius: 12px;
  background-color: var(--main-color);
}
.header .container .btns select {
  padding: 4px;
  border-radius: 14px;
  direction: rtl;
  outline: none;
  border: 2px solid var(--second-color);
  background-color: transparent;
  cursor: pointer;
  color: var(--white-color);
}
.header .container .btns select option {
  color: var(--bg-color);
}
.header .container .btns .menu-btn {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  transform: scaleX(0.9);
  font-size: 25px;
  border-radius: 14px;
  transition: var(--main-tran);
  background-color: var(--main-color);
}
.header .container svg {
  width: 43px;
  fill: var(--white-color);
  margin-top: 6px;
}
.header .container svg path {
  fill: var(--white-color) !important;
}
@media (max-width: 1200px) {
  .header .container .ul-links {
    display: none;
  }
  .header .container .logo .menu-btn {
    display: flex !important;
  }
}
.mob-menu {
  position: fixed;
  top: 88px;
  z-index: 999;
  width: 100%;
  padding: 15px;
  border-radius: 24px;
  background-color: var(--white-color);
  box-shadow: 0 0 7px #a3b9d291;
  transition: var(--main-tran);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
}
.mob-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  z-index: 1221;
}
.mob-menu .mob-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.mob-menu .mob-links .mob-link a {
  color: var(--bg-color);
}
@media (min-width: 1201px) {
  .mob-menu {
    display: none;
  }
}
