.main-title {
  display: flex;
  font-size: 36px;
  justify-content: center;
  margin: 0 auto 60px;
  padding:  5px;
  position: relative;
  width: fit-content;
  color: var(--white-color);
}

.main-title::before,
.main-title::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--main-color);
  box-shadow: 0px 0px 3px var(--second-color);
}
.main-title::before {
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}
.main-title::after {
  left: -30px;
  top: 55%;
  transform: translateY(-55%);
}
.main-title:hover::before {
  z-index: -1;
  animation: right-move 0.6s linear forwards;
}
.main-title:hover::after {
  z-index: -1;
  animation: left-move 0.6s linear forwards;
}
@keyframes left-move {
  50% {
    left: 0;
    width: 12px;
    height: 12px;
  }
  100% {
    left: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
}
@keyframes right-move {
  50% {
    right: 0;
    width: 12px;
    height: 12px;
  }
  100% {
    right: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
}