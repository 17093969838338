
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cairo", sans-serif;
  font-weight: 600;
}
:root {
  --main-color: #fdb728;
  --second-color: #d8a540;
  --third-color: #d6b572;
  --text-color: #f2ece7;
  --subText-color: #ada3a3;
  --white-color: #fff;
  --bg-color: #131010;
  --main-tran: 0.5s;
}
::selection {
  color: var(--white-color);
  background-color: var(--main-color);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--bg-color);
}
::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
html {
  scroll-behavior: smooth;
}
body {
  background: rgb(47,10,10);
  background: linear-gradient(90deg, rgb(53, 23, 23) 0%, rgb(32, 52, 53) 100%, rgba(95,146,161,1) 100%);
}
body,
html {
  overflow-x: hidden;
}
:dir(ltr) {
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}
.container {
  width: 90vw;
  margin: auto;
}
