/********about details***********/
.about-details {
  padding-top: 70px;
}
.about-details .container {
  width: 90%;
  height: 100%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 60px;
  padding: 35px 0;
}
.about-details .contentLeft,
.about-details .contentRight {
  width: 100%;
}
.about-details .contentLeft .row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
.about-details .contentLeft .row .imgWrapper {
  width: 100%;
  height: 450px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  
}
.about-details .contentLeft .row .imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  transition: 0.3s ease;
  animation: moveUpDown 3s ease-in-out 1.5s infinite;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.about-details .contentLeft .row .imgWrapper:nth-child(2) img {
  animation-delay: 4s;
}
.about-details .contentLeft .row .imgWrapper:nth-child(3) img {
  animation-delay: 3s;
}

.about-details .contentLeft .row .imgWrapper:nth-child(odd) {
  transform: translateY(-20px);
}
.about-details .contentLeft .row .imgWrapper:nth-child(even) {
  transform: translateY(20px);
}
.about-details .contentRight .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.about-details .contentRight .content h4 {
  font-size: 22px;
  font-weight: 400;
  color: var(--main-color);
}

.about-details .contentRight .content h1 {
  font-size: 24px;
  font-weight: 700;
  color: var(--white-color);
}

.about-details .contentRight .content p {
  font-size: 16px;
  color: var(--white-color);
  line-height: 28px;
  padding-bottom: 10px;
}
.about-details .contentRight .content a {
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 13px 30px;
  color: #fff;

  background-color: var(--second-color);
  border-radius: 8px;
  user-select: none;
}
.about-details .contentRight .content a:hover{
  background: #d35400;
}
@media (max-width: 768px) {
  .about-details .container {
    grid-template-columns: 1fr;
  }
  .about-details .contentLeft .row {
    grid-template-columns: repeat(2, 1fr);
  }
  .about-details .contentLeft .row .imgWrapper {
    height: 150px;
  }
  .about-details .contentRight .content h4 {
    font-size: 18px;
  }
  .about-details .contentRight .content h2 {
    font-size: 30px;
  }
}

.about-us {
  padding-top: 170px;
  padding-bottom: 170px;
}
.about-us .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}
.about-us .container .about-box {
  background-color: #473d3d;
  text-align: center;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid var(--main-color);
  margin: 0 auto 10px;
}
.about-us .container .about-box:hover {
  background-color: var(--second-color);
}
.about-us .container .about-box:hover .number {
  background-color: #2c2323;
}
.about-us .container .about-box .number {
  font-size: 30px;
  background-color: var(--main-color);
  color: var(--white-color);
  margin: 0 auto;
  padding: 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  width: 86px;
  position: relative;
  top: -30px;
}

/***********our mission*************/

.our-mission .main-heading {
  position: relative;
  color: var(--white-color);
  margin: 0 auto;
  text-align: center;
  font-size: 25px;
}
.arrow {
  position: absolute;
  top: 20%;
  right: 58%;
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid gold;
  border-right: 5px solid gold;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

.arrow-2 {
  position: absolute;
  top: 20%;
  right: 40%;
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow-2 span {
  display: block;
  width: 30px;
  height: 30px;
  border-top: 5px solid gold;
  border-left: 5px solid gold;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow-2 span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow-2 span:nth-child(3) {
  animation-delay: -0.4s;
}

.our-mission .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 50px;
  padding-bottom: 130px;
}
.our-mission .container .success-box {
  position: relative;
  padding: 10px 20px;
  border-radius: 14px;
  background-color: #332c2c;
  box-shadow: 0 0 4px rgb(163 185 210 / 20%);

}
.our-mission .container .success-box svg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 60px;
  border-radius: 16px;
  top: 50%;
  transform: translateY(-50%);
  right: -17px;
  transition: var(--main-tran);
  color: var(--white-color);
  background-color: var(--main-color);
}

.our-mission .container .success-box svg:hover {
  border-radius: 50%;
}
.our-mission .container .success-box p {
  font-size: 22px;
  font-weight: bold;
  margin-right: 35px;
  margin-left: 40px;
  color: var(--second-color);
  transition: var(--main-tran);
}
.our-mission .container .success-box:hover p {
  margin-right: 40px;
}
.our-mission .container .sucess-item-IconEn {
  right: inherit !important;
  left: -17px;
}

/**********work scope*************/
.work-scope {
  padding-bottom: 100px;
}
.work-scope .container {
  display: flex;
}
.panel {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60vh;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  flex: 0.5;
  margin: 10px;
  position: relative;
  transition: 700ms ease-in-out;
  -webkit-transition: 700ms ease-in-out;
  -moz-transition: 700ms ease-in-out;
  -ms-transition: 700ms ease-in-out;
  -o-transition: 700ms ease-in-out;
}

.panel h3 {
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin: 0;
  opacity: 0;
}

.panel.active {
  flex: 5;
}

.panel.active h3 {
  opacity: 1;
  transition: opacity 0.3s ease-in 0.4s;
}

@media (max-width: 1270px) {
  .arrow-2 {
    right: 30%;
  }
  .arrow {
    right: 65%;
  }
}
@media (max-width: 700px) {
  .arrow-2 {
    right: 10%;
  }
  .arrow {
    right: 76%;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
  }
}
